
::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path style="cursor: pointer; fill: aqua" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

* {
  font-family: "MADEEvolveSans", sans-serif!important;
}

@font-face {
  font-family: 'MADEEvolveSans';
  src: url('../fonts/MADE Evolve Sans Light EVO.otf');
  font-weight: 300;
}
@font-face {
  font-family: 'MADEEvolveSans';
  src: url('../fonts/MADE Evolve Sans Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'MADEEvolveSans';
  src: url('../fonts/MADE Evolve Sans Medium.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'MADEEvolveSans';
  src: url('../fonts/MADE Evolve Sans Bold.otf');
  font-weight: 600;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.d-none {
  display: none!important;
}

.MuiTypography-h3 {
  font-weight: 600;
}

body .MuiTypography-body1 {
  color: #ffffff;
}

body .MuiFormControl-marginDense {
  margin-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiDrawer-docked {
  background-color: #09102A!important;
}

.MuiDrawer-docked .MuiSvgIcon-root {
  color: #ffffff;
}

.MuiTypography-colorTextSecondary {
  font-weight: 500!important;
}

.MuiList-root a {
  text-decoration: none;
}

.MuiDrawer-root {
  background-color: #1c2023;
}

.MuiButton-textPrimary {
  color: #1c2023;
}

.MuiTabs-root {
  color: #ffffff;
  padding-bottom: 10px;
}

body .MuiTab-fullWidth,
body .form-tab {
  border-bottom: 1px solid #ffffff;
}

body .form-tab {
  flex: 1;
}

body .mybg .MuiTabs-flexContainer {
  border-bottom: 1px solid #ffffff;
}

.myfooter {
  background-color:#1C2023 !important;
}

.MuiButton-textPrimary {
  color: #ffffff !important;
  background: #5A35B6 !important;
  font-weight: 500 !important;
}

.MuiButton-textSizeSmall {
  padding: 7px 14px !important;
  font-size: 0.8125rem !important;
}

.MuiTableSortLabel-root {
  font-weight: 700;
  white-space: nowrap;
  padding: 10px 0 !important;
}

body .RaListToolbar-toolbar-52,
body .RaListToolbar-toolbar-68 {
  align-items: center;
  padding-right: 0;
}

body .MuiFilledInput-root,
body .MuiFilledInput-root:hover {
  background-color: transparent;
}

body .MuiFilledInput-input,
body .MuiInputLabel-formControl {
  color: #ffffff;
  padding-right: 25px;
}

body .MuiFilledInput-underline:before,
body .MuiFilledInput-underline:hover:before {
  border-bottom: 2px solid #ffffff;
}

.search {
  background-image: url(../images/search.png);
  background-repeat:no-repeat;
  background-position: center right;
}

body .MuiFormControl-root ::placeholder {
  color: white !important;
}

body .RaFilterButton-root-67,
body .RaFilterButton-root-82,
body .RaFilterButton-root-71 {
  display: inline-block;
  margin-right: 12px;
}

body .MuiIconButton-root {
  color: #5A35B6;
}

body .MuiTableCell-sizeSmall {
  padding: 6px 10px 6px 10px;
}

body .MuiTableRow-root {
  height: 65px;
}

body .MuiTableCell-root {
  border: 0;
  font-size: 16px;
  color: #ffffff;
  background: #0d1841!important;
}

body .MuiTableSortLabel-root:hover {
  color: #ffffff;
}

body .MuiDivider-root {
  height:0;
}

body .MuiTablePagination-toolbar {
  color: #ffffff;
}

body .MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #ffffff;
}

body .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color: #ffffff;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.next-page.MuiButton-textPrimary.MuiButton-textSizeSmall.MuiButton-sizeSmall, body .MuiButton-root {
  margin-left: 10px;
}

body .tabbed-form {
  background: #1c2023;
}

body .MuiTableCell-sizeSmall:last-child .RaButton-smallIcon-11 {
  font-size: 14px;
  width: 75px;
}

body .mybg .MuiFilledInput-input,
body .mybg .MuiInputLabel-formControl {
  color: #ffffff;
}

body .mybg .MuiFilledInput-underline:before, 
body .mybg .MuiFilledInput-underline:hover:before {
  border-bottom: 1px solid #A6A6A7;
}

body .mybg .MuiFilledInput-underline input {
  padding-left: 0;
}

body .mybg .MuiButton-root {
  margin-left: 0;
}

body .MuiInputLabel-filled.MuiInputLabel-marginDense {
  transform: translate(0, 17px) scale(1);
}

body .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
  transform: translate(0, 7px) scale(0.75);
}

body .ra-rich-text-input .ql-toolbar.ql-snow {
  background-color:#181c1f;
  padding:10px;
}

body .ra-rich-text-input .ql-toolbar.ql-snow .ql-picker-label {
  color:#fff;
  opacity:0.7;
}

body .ra-rich-text-input .ql-toolbar.ql-snow + .ql-container.ql-snow {
  height:200px;
  color:#fff;
}

body .mb-5 {
  margin-bottom: 30px;
}

body .ra-rich-text-input .ql-toolbar.ql-snow button .ql-stroke {
  stroke: #fff;opacity:0.7;
}

body .RaCreate-card-117 {
  border-radius: 0px;
}

body .ql-container.ql-snow {
  border-bottom: 0px solid transparent !important;
}

body .RaListToolbar-toolbar-56 {
  justify-content: flex-end;
}

body .MuiPaper-root.MuiCard-root.RaList-content-52.MuiPaper-elevation1.MuiPaper-rounded {
  max-width: 1060px;
  overflow: auto !important;
}

body .RaList-content-52 {
  background: #232228;
}

body .list-page.RaList-root-50 .RaList-content-52 {
  background: #181c1f;
}

body .table-responsive.list-page.RaList-root-50 .RaList-content-52 {
  background: #1c2023;
}

body .mycards,
body .stripecards {
  margin-bottom: 25px;
  margin-top: 25px;
}

body .RaEmpty-message-114,
body .RaNotFound-message-119 {
  color: #fff;
  font-weight: 400;
  opacity: 1;
}

body .mycards .MuiPaper-root {
  background: #5A35B6;
  border-radius: 12px;
  border: 0;
  min-height: 100%;
}

body .mycards .MuiPaper-root h3 {
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
}

body .mycards .MuiPaper-root span {
  font-weight: 400;
  color: white;
}

body .stripecards .MuiPaper-root {
  border: 0;
  min-height: 100%;
  border-radius: 20px;
  box-shadow: 0px 37px 54px -30px rgb(0 0 0 / 75%);
  background-image: linear-gradient(to right bottom, #181c1f, #1e404c, #0b6974, #009694, #1dc3a7);
}

body .stripecards .MuiPaper-root h3 {
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
}

body .text-danger {
  color: #d50000 !important;
}

body .appmenu  .MuiIconButton-root {
  color: #ffffff;
}

body svg.MuiSvgIcon-root.RaAppBar-menuButtonIconOpen-18 {
  color: #5A35B6;
}

.submenu {
  position: relative;
}

.submenu:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  width: 1px;
  height: 100%;
  background: #4F4E4E;
}

body .appmenu {
  background: transparent;
  box-shadow: none;
}

.MuiToolbar-root.MuiToolbar-dense.RaAppBar-toolbar-15:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 240px;
  height: 100%;
  z-index: -1;
  background: #1c2023;
}

body thead tr th {
  background-color: #1f2326 !important;
}

body .MuiTab-textColorInherit.Mui-selected {
  background-color: rgba(90, 53, 182, .5);
}

body .MuiTab-textColorInherit.Mui-selected span {
  color: #ffffff;
}

body .table-responsive .MuiTableCell-sizeSmall {
  padding: 6px 24px 6px 16px;
}

.d-flex {
  display:flex;
  align-items:center;
}

.justify-space-between {
  justify-content:space-between;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.font-weight-med {
  font-weight: 600;
}

body .btngreen {
  font-size: 16px;
  min-width: 125px;
  border: 1px solid #5A35B6;
  color: #5A35B6 !important;
  background: transparent !important;
}

body .btngreen:hover {
  background: #5A35B6 !important;
  border: 1px solid #5A35B6;
  color: #fff !important;
}

body .btnred {
  font-size: 16px;
  min-width: 125px;
  border: 1px solid #fa6a6a;
  color: #fa6a6a !important;
  background: transparent !important;
  text-transform: uppercase;
}

body .btnred:hover {
  color: #fff!important;
  background: #fa6a6a!important;
  border: 1px solid #fa6a6a;
}

.max-200 {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

body .editinputs .MuiFilledInput-input {
  padding-left:0;
  padding-right:0;
  padding-top: 30px;
  padding-bottom: 8px;
  color: #fff;
}

body .editinputs .MuiFilledInput-underline:before, body .MuiFilledInput-underline:hover:before {
  border-bottom: 1px solid #767677;
}

body .myforms {
  width: 745px;
  margin-left: auto;
  margin-right: auto;
}

video {
  border: 3px solid #252a2d;
}

.previews {
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
}

.previews div {
  margin-right: 25px;
  position: relative;
  margin-bottom: 25px;
}

.previews div button {
  position: absolute;
  right: -10px;
  padding: 0;
  top: -10px;
}

.makeStyles-root-61 > * {
  margin: 0!important;
}

.RaSimpleFormIterator-line-675 {
  border-bottom: 0!important;
}

.halfclosemenu {
  width: 55px;
}

.halfclosemenu .MuiListItemText-root {
  display: none;
}

.halfclosemenu .MuiList-root {
  width: 40px;
}

.halfclosemenu  svg.MuiSvgIcon-root {
  display: none;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

input#date {
  color: #ffffff;
}

#react-admin-title .MuiTypography-root.MuiTypography-gutterBottom {
  color: #ffffff;
}

#react-admin-title span {
  color: #ffffff;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  text-transform: capitalize;
}

.service-addBtn {
  margin-top: 20px;
  padding: 12px 20px;
  background: #5A35B6;
  color: #ffffff;
  border: none;
  font-size: 16px;
  letter-spacing: 0.02857em;
  cursor: pointer;
}

.detail-input input {
  width: 350px;
}

.MuiTableSortLabel-root {
  white-space: normal !important;
}

.MuiMenu-list {
  background: #5A35B6;
}

.MuiTypography-body2 {
  font-size: 15px!important;
}

.makeStyles-root-73 .MuiSelect-root, .makeStyles-root-73 .MuiInputBase-input {
  margin-top: 0!important;
}

.MuiFormHelperText-root.Mui-error {
  color: red!important;
  font-size: 14px;
  margin-left: 0;
}

.MuiSelect-icon {
  color: #5A35B6!important;
}

.iframe__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.iframe__wrapper iframe {
  width: 48%;
  height: 300px;
  margin-bottom: 40px;
}

.MuiFilledInput-underline.Mui-disabled:before {
  border-bottom-style: solid!important;
}

.MuiFormLabel-root.Mui-disabled {
  color: #ffffff!important;
}

.MuiButton-contained.Mui-disabled {
  color: #ffffff!important;
}

/* input:-webkit-autofill { 
  background-clip: text!important;
  -webkit-background-clip: text!important;
  -webkit-text-fill-color: #ffffff;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */

@media (min-width: 1280px){
  body .MuiContainer-maxWidthLg {
    max-width: 100%;
  }
  .MuiPaper-root.MuiCard-root.RaList-content-48.MuiPaper-elevation1.MuiPaper-rounded{
    width: 1000px !important;
    overflow: auto !important;
    background: #5A35B6;
  }
}

@media(max-width:1280px){
  body .btngreen{
    min-width: 105px;
  }
  body .btnred{
    min-width: 105px;
  }
  .RaLayout-content-122{
    padding: 8px;
  }
  .MuiContainer-root{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .RaEdit-main-64 .MuiTab-root {
    min-width: 149px !important;
  }
  body .myforms{
    width: 620px;
  }
  .makeStyles-title-1{
    padding: 0 0 0 0 !important;
  }
  body .MuiTableRow-root {
    height: 50px;
  }
}

@media (min-width: 600px){
  body .mybg .MuiToolbar-gutters {
    padding-left: 15px;
    padding-right: 24px;
  }
}

@media (min-width: 0px){
  .RaLayout-content-6 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}